import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import { routesConfig } from './RoutesConfig';
import { useSelector } from 'react-redux';
import history from '@/utils/history';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/pages/auth/Logout'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/auth/NotFound'));

const SubMenuRouter = ({ subMenuRouter }) => {
  subMenuRouter.map((subMenu) => {
    console.log('🚀 ~ file: AppRouter.jsx ~ line 25 ~ routeItem.hasSubMenu.map ~ subMenu', subMenu);

    return (
      <PrivateRoute
        key={subMenu.component}
        path={subMenu.path}
        exact={subMenu.exact || true}
        component={lazy(() =>
          import(/* webpackChunkName: "[request]" */ `@/pages/${subMenu.component}`)
        )}
      />
    );
  });
};

export default function AppRouter() {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const { isLoggedIn } = window.localStorage.getItem("auth") ? JSON.parse(window.localStorage.getItem("auth")) : { isLoggedIn: false };
  // console.log(isLoggedIn, 'isLoginisLoginisLogin');
  if (!isLoggedIn) {
    window.location.href = window.location.origin
  }
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          {routesConfig.map((routeItem) => {
            return (<PrivateRoute
              key={routeItem.component}
              path={`${routeItem.path}`}
              exact={routeItem.exact || true}
              type={routeItem.type}
              component={lazy(() =>
                import(/* webpackChunkName: "[request]" */ `@/pages/${routeItem.folder}${routeItem.component}`)
              )}
            />
            );
          })}
          <PublicRoute path="/login" render={() => <Redirect to="/" />} exact />
          <Route component={Logout} path="/logout" exact />

          <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
