import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, type, ...rest }) => {
  const { is_admin, is_primary_company, is_rdcheckout } = useSelector((state) => state.auth);
  const config = {
    type: 'spring',
    damping: 20,
    stiffness: 100,
  };

  return (
    // Show the component only when the admin is logged in
    // Otherwise, redirect the admin to /signin page

    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem('isLoggedIn') ? (
          ((type === 4) || (type === 0 && !is_rdcheckout) || (type === 1 && is_admin) || (type === 2 && is_primary_company) || (type === 3 && is_rdcheckout)) ? (
            <motion.div
              transition={config}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
            >
              <Component {...props} />
            </motion.div>
          ) : <Redirect to="/" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
